@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .curly-underline {
    background-image: url('./svg/curly-underline.svg');
    background-repeat: no-repeat;
    background-size: 100% 4px;
    background-position-y: bottom;
    padding-bottom: 4px; /* Adjust based on SVG height */
  }
}

/* src/index.css */
body {
    margin: 0;
    overscroll-behavior: contain;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantaloupe", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    overflow: auto;
  }
  